<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/0精选案例-智能仓储-切图.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">布局原料赋码，助力品质生产</div>
            <div class="mobile-head-explain-content">原料流转过程管控与质量追溯</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">近年来，零售快消行业品牌竞争激烈，如何在激烈的
                        市场竞争中保持稳健增长，同时保障产品品质及企业生产效力提高是企业一直探索前
                        进的方向。该企业对原料（尤其主剂、农产品）存储环境、有效期管理要求严格，然
                        而企业物料管理细分场景增多，产品需求呈现小批次、多品类，增加了企业仓储管理
                        的难度。针对该企业现状提出智能仓储，进行原料流转过程管控与质量追溯管理，实
                        现企业上下游信息无缝衔接，减少非必要作业流转的沟通，建立生产力报告数据基础
                        ，布局原料赋码，助力品质生产。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-content1" style="height:10.6rem">该企业在精细化管理原料到生产过程时，仍面临以下挑战</div>
            <div class="ware-box1">
                <div class="title1">
                    多SKU、多环节流转，物料管理繁杂
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    纸质记录信息不实时，数据未得到有效利用
                </div>
            </div>
            <div class="ware-box1" style="margin-bottom:12.4rem">
                <div class="title1">
                    各环节作业过程不透明，质量问题溯源困难
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="ware-content1" style="height:48.7rem;line-height: 5rem;">
                以“简洁、高效、防呆”为原则，比孚智能仓储采用
                “统一赋码管理+移动作业”模式，通过智能PDA扫码
                硬件实时采集物料信息并流转作业，结合智能仓储
                系统与采购、生产、财务协同，实现原料入库到生
                产使用全流程管理。智能仓储提供原料便捷入库报
                检，发料按需创建及自动下发，生产投退料管理，以
                及库存调拨、盘点管理、原料成品溯源等功能，真正
                实现仓储到生产的智能化、数字化、快速反应、高效
                可追溯管理。</div>
                <img class="ware-img" src="../../assets/image/selected/03-1-1解决方案1.svg"/>
                <div class="ware-title" style="margin-top:4.7rem">原料入库管理</div>
                <div class="ware-content1" style="margin-top:1rem">原料一托一码，批次管理，PDA便捷收货，自动报检，快速入库；</div>
                <div class="ware-title" style="margin-top:1rem">发料出库管理</div>
                <div class="ware-content1" style="margin-top:1rem">支持多种模式发料需求创建，任务自动下发PDA，仓库按需拣货发料；</div>
                <div class="ware-title" style="margin-top:1rem">生产投料管理</div>
                <div class="ware-content1" style="margin-top:1rem">CIP关联订单，绑定投料口物料；调配缸次投料单，PDA扫描投料口自动带出对应物料；</div>
                <div class="ware-title" style="margin-top:1rem">数据报表查询</div>
                <div class="ware-content1" style="margin-top:1rem">物料流转数据自动统计，包含收发存明细汇总，实时库存，投料明细等；</div>
                <div class="ware-title" style="margin-top:1rem">可视化数字看板</div>
                <div class="ware-content1" style="margin-top:1rem">实时监控果汁果肉解冻时长、物料保质到期情况，自动预警。</div>
                <img class="ware-img2" style="margin-top:3rem;margin-bottom: 9rem ;" src="../../assets/image/selected/03-1-1解决方案2.svg"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
                <div class="ware-title" style="margin-top:6.2rem">提高物料管理准确性，实现系统账实相符</div>
                <div class="ware-content1" style="margin-top:1rem">通过物资托盘化标签管理，PDA扫描数据采集进行实物
                    管理，全流程线上化操作，减少因错误作业或信息不流通所引起的信息不符。</div>
                <div class="ware-title" style="margin-top:4.8rem">降低企业仓储管理运营成本</div>
                <div class="ware-content1" style="margin-top:1rem">通过智能仓储线上化管理，提高仓库信息化程度、整体
                    仓储物流管理水平，仓储人员素质，并且形成标准作业规范，减低人员流动成本，降低因信息流和作业流无法统一致使总体仓储成本提高。</div>
                <div class="ware-title" style="margin-top:4.8rem">提高生产力效率</div>
                <div class="ware-content1" style="margin-top:1rem">基于条码进行精确的库位管理和PDA扫描辅助存储与拣选工具，提升现场作业效
                    率与准确率，从而减少原料从收货到投料时间，提高生产力效率。</div>
                <div class="ware-title" style="margin-top:4.8rem">高效管控，便捷溯源</div>
                <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">系统全流程跟踪原料到成品过程，双向溯源，精确定位问题源头，一键查找，便
                    捷追溯某个环节产生问题批次的产品。</div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
</style>